import React, { useCallback, useRef } from 'react';
import Circle from '@mui/icons-material/Circle';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormContext, useWatch } from 'react-hook-form';
import { InputField } from './InputField';

export type PpPinInput = {
  dataQa?: string;
  name: string;
  pinLength: number;
};

const inputRegex = /^[0-9]*$/;

const Container = styled('div')({
  position: 'relative',
});

const FakeInput = styled('div')<{ isActive: boolean }>(({ isActive, theme }) => ({
  alignItems: 'center',
  background: theme.palette.input.borderNormal,
  borderRadius: theme.borderRadius.sm,
  boxShadow: isActive ? `0 0 0 1px ${theme.palette.input.borderActive}` : 'none',
  color: theme.palette.text.primary,
  display: 'flex',
  flex: '1 0',
  height: theme.spacing(7),
  justifyContent: 'center',
  padding: theme.spacing(1),
}));

const StyledCircle = styled(Circle)(() => ({
  fontSize: '1rem',
}));

const RealInput = styled(InputField)(({ theme }) => ({
  '.MuiFormHelperText-root': {
    color: theme.palette.error.main,
    margin: theme.spacing(1, 0, 0),
  },
  '.MuiInputBase-root': {
    height: theme.spacing(7),
    opacity: 0,
  },
  position: 'absolute',
  top: 0,
  width: '100%',
}));

export function PinInput({ dataQa = 'pin', name, pinLength }: PpPinInput): React.ReactElement {
  const { setValue } = useFormContext();
  const value = useWatch<Record<string, string>>({ name });
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const newValue = e.target.value;
      if (newValue.length > pinLength || !inputRegex.test(newValue)) {
        return;
      }
      setValue(name, newValue, { shouldValidate: true });
    },
    [setValue, name, pinLength],
  );

  return (
    <Container>
      <Stack direction="row" spacing={0.5}>
        {Array.from({ length: pinLength }).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <FakeInput key={`pin-${i}`} isActive={i === value.length}>
            {i < value.length && <StyledCircle data-qa="circle" />}
          </FakeInput>
        ))}
      </Stack>
      <RealInput
        autoFocus
        autoComplete="off"
        dataQa={dataQa}
        inputMode="numeric"
        inputRef={inputRef}
        name={name}
        slotProps={{
          htmlInput: {
            inputMode: 'numeric',
          },
        }}
        type="text"
        onBlur={(): void => inputRef.current?.focus()}
        onChange={handleChange}
      />
    </Container>
  );
}

/* eslint-disable react/no-multi-comp */
import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';

export function OnboardingTitle({
  children,
}: Pick<TypographyProps, 'children'>): React.ReactElement {
  return (
    <Typography
      sx={{
        '+ .MuiTypography-paragraphBodyS': {
          mt: 1,
        },
      }}
      variant="subHeadingS"
    >
      {children}
    </Typography>
  );
}

export function OnboardingParagraph({
  children,
  textAlign,
}: Pick<TypographyProps, 'children' | 'textAlign'>): React.ReactElement {
  return (
    <Typography color="text.light" textAlign={textAlign} variant="paragraphBodyS">
      {children}
    </Typography>
  );
}

export function SceneTitleLarge({
  children,
}: Pick<TypographyProps, 'children'>): React.ReactElement {
  return (
    <Typography
      color="text.dark"
      sx={{
        '+ .MuiTypography-paragraphBodyM': {
          mt: 2,
        },
        '+ .MuiTypography-subHeadingM': {
          mt: 1,
        },
      }}
      variant="headingL"
    >
      {children}
    </Typography>
  );
}

export function SceneTitleMedium({
  children,
}: Pick<TypographyProps, 'children'>): React.ReactElement {
  return (
    <Typography
      color="text.dark"
      sx={{
        '+ .MuiTypography-paragraphBodyM': {
          mt: 1,
        },
      }}
      variant="subHeadingL"
    >
      {children}
    </Typography>
  );
}
export function SceneSubTitle({ children }: Pick<TypographyProps, 'children'>): React.ReactElement {
  return (
    <Typography
      color="text.light"
      component="h3"
      sx={{
        '+ .MuiTypography-paragraphBodyM': {
          mt: 1,
        },
      }}
      variant="subHeadingM"
    >
      {children}
    </Typography>
  );
}

type PpSceneParagraph = Pick<TypographyProps, 'children' | 'sx'>;
export function SceneParagraph({ children, sx }: PpSceneParagraph): React.ReactElement {
  return (
    <Typography
      color="text.light"
      sx={{
        ...sx,
        '+ .MuiTypography-paragraphBodyM': {
          mt: 2,
        },
      }}
      variant="paragraphBodyM"
    >
      {children}
    </Typography>
  );
}

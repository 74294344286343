import { Stack } from '@mui/material';
import type { StackProps } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { SceneMain } from '../scene/SceneMain';

type PpOnboardingSceneMain = {
  StackProps?: StackProps;
  dataQa?: string;
} & PpWC;

export function OnboardingSceneMain({
  children,
  dataQa,
  StackProps,
}: PpOnboardingSceneMain): React.ReactElement {
  return (
    <SceneMain dataQa={dataQa} sx={{ height: 'auto', my: 4 }}>
      <Stack spacing={4} {...StackProps}>
        {children}
      </Stack>
    </SceneMain>
  );
}
